:root {
    --blue: rgb(162, 192, 252);
}

.footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    padding: 30px 0;
    position: relative;
}

.lightFooter {
    background-color: rgb(35, 35, 65);
}

.darkFooter {
    background-color: rgb(16, 16, 37);
}

.icons {
    display: flex;
    gap: 10px;
}

.info {
    font-size: 15px;
    font-weight: 600;
}

.lightIcon,
.lightInfo {
    color: aqua;
}

.lightIcon:hover {
    color: white;
}

.darkInfo {
    color: white;
}

.darkIcon:hover {
    color: aqua;
}

@media (max-width: 800px) {
    .footer {
        flex-direction: column;
        gap: 20px;
        justify-content: flex-start;
    }

    .info {
        text-align: center;
    }
}