h1,
p {
    margin: 0;
    padding: 0;
}

.container {
    display: flex;
    flex-direction: column;
    max-width: 30%;
    gap: 15px;
    border-radius: 15px;
}

.lightContainer {
    background-color: rgb(243, 238, 238);
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.darkContainer {
    background-color: rgb(24, 24, 46);
}

.container img {
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    width: 100%;
    object-fit: cover;
}

.containerInfo {
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 0 20px;
    margin-bottom: 20px;
}

.containerInfo h1 {
    font-size: 20px;
}

.tags {
    display: flex;
    gap: 10px;
}

.tag {
    background-color: transparent;
    font-size: 11px;
    padding: 7px 13px;
    border-radius: 10px;
    height: fit-content;
    text-align: center;
}

.lightTag {
    background-color: rgb(14, 14, 65);
    color: white;
}

.darkTag {
    background-color: rgb(18, 58, 107);
}

@media (max-width: 1175px) {
    .tags {
        flex-wrap: wrap;
    }
}

@media (max-width: 800px) {
    .container {
        max-width: 100%;
        gap: 10px;
    }
}