.container {
    display: flex;
    justify-content: space-between;
    padding: 30px 50px;
    border-radius: 20px;
    margin: 140px 0;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.lightContainer {
    background-color: rgb(207, 249, 249);
}

.darkContainer {
    background-color: rgb(17, 9, 58);
}

.firstChild {
    width: 45%;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.secondChild {
    width: 45%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 20px;
}

.title {
    position: relative;
}

.title:after {
    content: "";
    position: absolute;
    border: 2px solid blue;
    width: 20%;
    left: 0;
    bottom: -5px;
}

.contactInfos {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.infoContainer {
    display: flex;
    gap: 10px;
    align-items: center;
}

.iconContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    padding: 10px;
}

.lightIconContainer {
    background-color: blue;
}

.darkIconContainer {
    background-color: rgb(216, 209, 209);
}

.icon {
    font-size: 16px;
}

.lightIcon {
    color: white;
}

.darkIcon {
    color: black;
}

@media (max-width: 1000px) {
    .container {
        flex-direction: column;
        gap: 30px;
    }

    .firstChild {
        width: 100%;
    }

    .secondChild {
        width: 100%;
    }
}

@media (max-width: 600px) {

    .container {
        padding: 30px 30px;
        border-radius: 10px;
    }

    .container h1 {
        font-size: 25px;
    }
}

@media (max-width: 400px) {

    .container h1 {
        font-size: 20px;
    }

    .iconContainer {
        padding: 6px;
    }

    .icon {
        font-size: 12px;
    }

    .info {
        font-size: 12px;
    }
}