.header {
    display: flex;
    justify-content: space-between;
    position: relative;
    width: 100%;
    align-items: center;
    padding: 0 60px;
    margin-bottom: 20px;
}

.lightHeader {
    background-color: azure;
    border-bottom: 1px solid rgb(231, 219, 219, 0.4);
}

.darkHeader {
    background-color: rgb(11, 2, 25);
    border-bottom: 1px solid rgba(35, 14, 88, 0.4);
}

.navDesktop {
    display: flex;
    background-color: transparent;
    padding: 10px 40px;
    border-radius: 50px;
    gap: 60px;
}

.navDesktop a {
    font-size: 15px;
    padding: 10px 30px;
    font-weight: 600;
}

.lightNavDesktop a {
    color: black;
}

.active {
    position: relative;
}

.active:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: rgb(186, 186, 234);
    visibility: visible;
    transform: scaleX(1);
    transition: all 0.3s ease-in-out;
}

.notActive {
    position: relative;
}

.notActive:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: rgb(186, 186, 234);
    visibility: hidden;
    transform: scaleX(0);
    transition: all 0.3s ease-in-out;
}

.notActive:hover:after {
    visibility: visible;
    transform: scaleX(1);
}

.logoContainer img {
    width: 120px;
}

.navMobile {
    display: none;
}

.navBars {
    display: none;
}

.switchDesk {
    display: block;
}

.switchMob {
    display: none;
}

@media (max-width: 1024px) {
    .navDesktop {
        padding: 10px 20px;
        gap: 20px;
    }
}


@media (max-width: 800px) {

    .header {
        padding: 0 10px;
    }

    .navDesktop {
        display: none;
    }

    .navBars {
        display: block;
        margin-right: 30px;
        background-color: transparent;
        border: none;
    }

    .barsIcon {
        font-size: 32px;
        cursor: pointer;
    }

    .lightBarsIcon {
        color: black;
    }

    .lightBarsIcon:hover {
        color: gray;
    }

    .darkBarsIcon {
        color: white;
    }

    .darkBarsIcon:hover {
        color: rgb(220, 199, 199);
    }

    .navMobile {
        display: block;
        position: absolute;
        top: 120px;
        z-index: 999;
        width: 100%;
        left: 0;
        border-bottom-right-radius: 20px;
        border-bottom-left-radius: 20px;
    }

    .navDarkMob {
        background-color: #0a0a1d;
        box-shadow: 0 4px 6px -1px rgba(255, 255, 255, 0.3),
            0 2px 4px -1px rgba(255, 255, 255, 0.3);
    }

    .navLightMob {
        background-color: rgb(250, 250, 250);
        box-shadow: 0 4px 6px -2px rgba(56, 30, 94, 0.3),
            /* bottom shadow */
            0 2px 4px -1px rgba(31, 22, 112, 0.2);
        /* softer bottom shadow */
    }


    .mobLinks {
        display: flex;
        flex-direction: column;
        gap: 20px;
        padding: 20px 30px;
    }

    .mobLinks a {
        font-size: 20px;
        font-weight: 500;
    }

    .lightLink {
        color: black;
    }

    .lightLink:hover {
        color: gray;
    }

    .darkLink:hover {
        color: rgb(214, 214, 235);
    }

    .switchDesk {
        display: none;
    }

    .switchMob {
        display: block;
    }
}