h2 {
    margin: 0;
    padding: 0;
}

.main {
    display: flex;
    flex-direction: column;
    padding: 0 90px;
    gap: 30px;
    margin-bottom: 100px;
}

.head {
    align-self: center;
    width: 100%;
    position: relative;
}

.head h1 {
    font-size: 50px;
    text-align: center;
}

.projects {
    display: grid;
    gap: 20px;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(30%, 1fr));
}


@media (max-width: 1024px) {
    .projects {
        grid-template-columns: repeat(auto-fit, minmax(45%, 1fr));
    }

    .singleProject {
        width: 100%;
    }

    .infos {
        flex-direction: column;
        margin-top: 0;
    }

    .infos h2 {
        margin-top: 10px;
        font-size: 32px;
    }
}

@media (max-width: 750px) {
    .projects {
        grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
    }
}

@media (max-width: 500px) {
    .main {
        padding: 0 30px;
    }

    .infos h2 {
        margin-top: 10px;
        font-size: 26px;
    }
}