@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap');

:root {
    --blue: rgb(162, 192, 252);
    --darkBlue: rgb(38, 38, 171);
}


h1,
p,
h3 {
    margin: 0;
    padding: 0;
}

.dark {
    color: var(--blue);
}

.light {
    color: var(--darkBlue);
}

.main {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 0 90px;
}

.mainContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 30px;
}

.infoContainer {
    width: 50%;
    animation: slideIn 1s ease-out;
}

@keyframes slideIn {
    from {
        transform: translateX(-100%);
    }

    to {
        transform: translateX(0);
    }
}

.infoContainer span {
    font-size: 18px;
    margin-bottom: 5px;
}

.infoTitle {
    font-size: 40px;
}

.infoJs {
    font-size: 30px;
}

.infoContainer p {
    margin-top: 10px;
    width: 70%;
}

.imgContainer {
    width: 36%;
}

.imgContainer img {
    width: 100%;
}

.icons {
    display: flex;
    gap: 10px;
    margin-top: 10px;
}

.icon {
    font-size: 20px;
}

.darkIcon {
    color: var(--blue);
}

.lightIcon {
    color: var(--darkBlue);
}

.darkIcon:hover {
    color: rgb(255, 255, 255);
}

.lightIcon:hover {
    color: var(--blue);
}

.projects {
    display: flex;
    flex-direction: column;
    gap: 30px;
    margin-top: 60px;
}

.projectsHead {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.projectsHead h1 {
    font-size: 30px;
}

.projectsHead button {
    padding: 10px 30px;
    font-family: Outfit;
    font-weight: 700;
    font-size: 20px;
    border-radius: 50px;
    cursor: pointer;
}

.darkButton {
    border: 3px solid var(--blue);
    background-color: transparent;
    color: white;
}

.lightButton {
    border: 3px solid var(--darkBlue);
    background-color: transparent;
    color: var(--darkBlue);
}

.darkButton:hover {
    background-color: rgb(12, 12, 82);
}

.lightButton:hover {
    background-color: rgb(215, 224, 227);
}

.projectsList {
    display: flex;
    justify-content: space-between;
}

@media (max-width: 1175px) {
    .infoTitle {
        font-size: 45px;
    }

    .infoJs {
        font-size: 35px;
    }

    .infoContainer p {
        margin-top: 10px;
        width: 80%;
    }
}

@media (max-width: 1024px) {
    .infoTitle {
        font-size: 35px;
    }

    .infoJs {
        font-size: 28px;
    }

    .infoContainer p {
        font-size: 15px;
        width: 90%;
    }
}

@media (max-width: 800px) {
    .mainContainer {
        flex-direction: column;
        gap: 30px;
    }

    .infoContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        text-align: center;
    }

    .infoContainer p {
        width: 100%;
        font-size: 18px;
    }

    .infoTitle {
        font-size: 50px;
    }

    .infoJs {
        font-size: 40px;
    }

    .imgContainer {
        width: 90%;
    }

    .projectsHead {
        flex-direction: column;
        gap: 20px;
    }

    .projectsHead button {
        padding: 8px 25px;
        font-family: Outfit;
        font-weight: 700;
        font-size: 20px;
    }

    .projectsList {
        flex-direction: column;
        justify-content: flex-start;
        gap: 40px;
    }
}

@media (max-width: 500px) {
    .main {
        padding: 0 50px;
    }

    .infoTitle {
        font-size: 35px;
    }

    .infoJs {
        font-size: 28px;
    }

    .infoContainer p {
        font-size: 15px;
    }

    .projectsHead h1 {
        font-size: 35px;
    }
}

@media (max-width: 500px) {
    .main {
        padding: 0 30px;
    }
}