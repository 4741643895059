@import url('https://fonts.googleapis.com/css2?family=Lexend:wght@100..900&display=swap');


* {
  box-sizing: border-box;
  font-family: 'Lexend';
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Lexend';
}

.App {
  display: flex;
  flex-direction: column;
  background-color: #0a0a1d;
  min-height: 100vh;
  color: white;
}

.lightApp {
  display: flex;
  flex-direction: column;
  background-color: rgb(250, 250, 250);
  min-height: 100vh;
  color: black;
}

a {
  color: white;
  text-decoration: none;
}

footer {
  margin-top: auto;
}