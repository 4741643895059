.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    padding: 20px;
    border-radius: 10px;
    margin-top: 40px;
}

.lightContainer {
    background-color: rgb(249, 247, 247);
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.darkContainer {
    background-color: transparent;
}


.section {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
    margin-top: 10px;
}

.iconContainer {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 15px;
}

.icon {
    font-size: 50px;
}

.tsIcon {
    font-size: 43px;
}

.lightIcon,
.lightTsIcon {
    color: rgb(18, 18, 70);
}

.darkIcon,
.darkTsIcon {
    color: rgb(243, 236, 236);
}

@media (max-width: 500px) {
    .icon {
        font-size: 35px;
    }

    .tsIcon {
        font-size: 30px;
    }
}