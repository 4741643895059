.main {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    margin-top: 50px;
}

.main h1 {
    font-size: 100px;
    text-align: center;
}

.main p {
    font-size: 20px;
    text-align: center;
}

.main a {
    text-decoration: underline;
}

@media (max-width: 500px) {
    .main {
        margin-bottom: 100px;
        padding: 0 50px;
    }

    .main h1 {
        font-size: 70px;
        text-align: center;
    }
    
    .main p {
        font-size: 18px;
    }

}