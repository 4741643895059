.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    padding: 20px;
    border-radius: 10px;
    margin-top: 20px;
    text-align: center;
}

.lightContainer {
    background-color: rgb(243, 240, 248);
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.darkContainer {
    background-color: rgb(2, 10, 30);
}

.child {
    display: flex;
    justify-content: space-between;
}

/* .paragraph {
    background-color: rgb(255, 255, 255);
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 25%;
    border-radius: 20px;
    font-weight: 600;
} */

@media (max-width: 500px) {
    .container {
        padding: 10px;
    }
}