.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #d9d8e6;
    transition: .4s;
    border-radius: 34px;
}

.slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: .4s;
    border-radius: 50%;
}

input:checked + .slider {
    background-color: #103556;
}

/* Move the circle when checked */
input:checked + .slider:before {
    transform: translateX(26px);
}

.sun {
    color: rgb(207, 188, 188);
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    left: 16.5px;
    z-index: 900;
}

.moon {
    color:#0a0a1d;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    right: 4px;
    z-index: 901;
}