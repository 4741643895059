.main {
    padding: 0 90px;
    display: flex;
    margin-top: 30px;
    margin-bottom: 100px;
    flex-direction: column;
    gap: 60px;
}

.darkText {
    color: rgb(171, 193, 198);
}

.lightText {
    color: rgb(51, 10, 87);
}

.mainHead {
    align-self: center;
    display: flex;
    flex-direction: column;
    gap: 10px;
    text-align: center;
}

.mainHead h1 {
    display: flex;
    align-items: center;
    gap: 15px;
    font-size: 70px;
}


.mainContent {
    display: flex;
    flex-direction: column;
    gap: 20px;
}


@media (max-width: 1175px) {}

@media (max-width: 1024px) {
    .mainHead h1 {
        font-size: 80px;
    }
}

@media (max-width: 800px) {

    .mainHead h1 {
        font-size: 70px;
    }
}

@media (max-width: 500px) {

    .mainHead h1 {
        font-size: 45px;
    }


    .main {
        padding: 0 15px;
    }


    .mainHead p {
        font-size: 17px;
        width: 100%;
    }

}