.main {
    padding: 60px 90px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    min-width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 1;
}

.goBack {
    align-self: flex-start;
}

.goBackLink {
    display: flex;
    align-items: center;
    gap: 10px;
}

.goBackLinkLight {
    color: black;
}

.singleImg {
    display: flex;
    justify-content: space-between;
    position: relative;
    width: 100%;
    border: 2px solid red;
    display: flex;
    justify-content: center;
    align-items: center;
}

.singleImg img {
    position: fixed;
    width: 80%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    object-fit: cover;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.singleImg button {
    top: 50%;
    transform: translate(-50%, -50%);
    position: fixed;
    z-index: 3;
}

.leftBtn {
    left: 46px;
}

.rightBtn {
    right: 20px;
}

.mainImg {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.mainImg img {
    width: 45%;
    object-fit: cover;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    cursor: pointer;
}

.projectInfo {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 20px;
    width: 100%;
    padding: 20px;
    border-radius: 10px;
}

.lightProjectInfo {
    background-color: rgb(241, 246, 248);
}

.darkProjectInfo {
    background-color: rgb(21, 21, 45);
}

.projectInfo h1 {
    font-size: 40px;
}

.projectInfo p {
    width: 95%;
}

.icon {
    font-size: 30px;
}

.lightIcon {
    color: white;
}

.darkIcon {
    color: rgb(25, 26, 26);
}

.tech {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.items {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
}

.item {
    text-align: center;
    font-weight: 600;
    width: 150px;
    padding: 5px 10px;
    border-radius: 5px;
    color: white;
}

.darkItem {
    background-color: rgb(6, 6, 116);
    color: white;
}

.lightItem {
    background-color: rgb(14, 14, 65);
    color: white;
}

.gitContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.gitBtns {
    display: flex;
    align-items: center;
    gap: 15px;
}

.gitButton {
    width: 140px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    padding: 8px 0;
    padding-left: 15px;
    font-weight: 600;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.gitButtonLight {
    background-color: rgb(33, 33, 135);
    color: white;
}

.gitButtonLight:hover {
    background-color: rgb(9, 9, 99);
}

@media (max-width: 1024px) {
    .main {
        flex-direction: column;
        padding: 60px 90px;
    }

    .mainImg {
        flex-direction: column;
        gap: 20px;
    }

    .mainImg img {
        width: 100%;
    }

    .projectInfo {
        gap: 30px;
    }

    .projectInfo h1 {
        font-size: 50px;
    }

    .projectInfo p {
        width: 95%;
        font-size: 18px;
    }

    .tech {
        font-size: 20px;
    }

    .items {
        flex-wrap: wrap;
    }

}

@media (max-width: 800px) {

    .subTitle {
        font-size: 16px;
        position: relative;
        width: fit-content;
    }

    .subTitle:after {
        content: "";
        position: absolute;
        width: 40%;
        left: 50%;
        transform: translateX(-50%);
        bottom: -10px;
    }

    .ligthSubTitle:after {
        border: 1.5px solid black;
    }

    .darkSubTitle:after {
        border: 1.5px solid white;
    }

    .main {
        gap: 25px;
        padding: 40px 40px;
    }

    .main img {
        height: auto;
    }

    .projectInfo {
        gap: 20px;
        align-items: center;
        text-align: center;
    }

    .projectInfo h1 {
        align-self: center;
        font-size: 40px;
    }

    .projectInfo p {
        font-size: 17px;
    }

    .tech {
        align-items: center;
        font-size: 15px;
        gap: 20px;
    }

    .items {
        display: flex;
        justify-content: center;
    }

    .gitContainer {
        align-items: center;
        gap: 20px;
    }

    .gitBtns {
        flex-direction: column;
        align-items: center;
        gap: 10px;
        width: 60%;
    }

    .gitLink {
        width: 70%;
    }

    .gitButton {
        width: 100%;
        margin: 0;
    }

}

@media (max-width: 500px) {
    .main {
        margin-bottom: 40px;
        padding: 0 30px;
    }

    .projectInfo {
        padding: 10px;
    }

    .singleImg img {
        width: 95%;
    }

    .projectInfo h1 {
        font-size: 30px;
    }

    .projectInfo p {
        font-size: 13px;
    }

    .item {
        font-size: 14px;
    }

    .gitBtns {
        flex-direction: column;
        align-items: center;
        gap: 10px;
        width: 60%;
    }

    .gitLink {
        width: 100%;
    }

    .gitButton {
        width: 100%;
        margin: 0;
    }
}