.overlay {
    position: fixed;
    top: 0;
    left: 0;
    min-width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 1;
}

.modal {
    position: fixed;
    top: 50%;
    gap: 10px;
    background-color: azure;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    padding: 30px;
    width: 40%;
    z-index: 2;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 10px;
}

.content {
    display: flex;
    flex-direction: column;
    gap: 20px;
    position: relative;
    text-align: center;
}

.link {
    color: blue;
    text-decoration: underline;
}

@media (max-width: 750px) {
    .modal {
        width: 95%;
    }
}