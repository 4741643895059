.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    padding: 20px;
    border-radius: 10px;
    margin-top: 40px;
}

.lightContainer {
    background-color: rgb(243, 240, 248);
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.darkContainer {
    background-color: rgb(2, 10, 30);
}

.paragraph {
    margin: 0;
    padding: 0;
}

@media (max-width: 500px) {
    .container {
        padding: 10px;
    }
}