.valueContainer {
    display: flex;
    flex-direction: column;
    gap: 30px;
    padding: 30px;
    border-radius: 20px;
    margin: 60px 0;
    align-items: center;
}

p,
h1 {
    margin: 0;
    padding: 0;
}

.titleCtn {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
}

.title {
    position: relative;
}

.title:after {
    content: "";
    position: absolute;
    border: 2px solid blue;
    bottom: -5px;
    left: 50%;
    transform: translateX(-50%);
    width: 30%;
}

.values {
    display: flex;
    justify-content: space-between;
}

.value {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 30%;
    padding: 20px;
    text-align: center;
    gap: 10px;
    border-radius: 15px;
}

.darkValue {
    background-color: rgb(18, 18, 37);
    color: aqua;
}

.ligthValue {
    background-color: rgb(212, 233, 244);
}

.valueTitleLight {
    color: rgb(24, 24, 106);
}

.imageContainer {
    width: 70%;
}

.image {
    width: 100%;
}

@media (max-width: 1024px) {
    .values {
        flex-direction: column;
        gap: 35px;
    }

    .value {
        width: 100%;
    }
}


@media (max-width: 800px) {

    .value h2 {
        font-size: 26px;
    }

}

@media (max-width: 500px) {

    .valueContainer {
        padding: 0;
    }

    .value p {
        font-size: 15px;
        text-align: center;
        margin-top: 10px;
    }

    .valueContainer h1 {
        font-size: 28px;
    }
}